<template>
  <div
    class="u-display-flex u-flex-wrap-yes u-height-100 u-width-100 u-flex-align-items-center u-flex-justify-content-center"
  >
    <custom-tool-tip
      v-if="data.length"
      :tippy="{ interactive: false, offset: '-72,0' }"
    >
      <div slot="toolTipData">
        <div class="tooltip_container">
          <div class="u-spacing-pb-m u-font-weight-600 u-font-size-2">
            <rb-icon
              class="u-color-grey-lighter"
              icon="alerts"
            />
            {{ tooltipTitle }}
          </div>
          <div class="chips">
            <span
              v-for="(recType, index) in data"
              :key="index"
              class="tag-token rec-chip u-font-size-6 u-font-weight-400"
            >
              {{ formattedData(recType) }}
            </span>
          </div>
        </div>
      </div>
      <div
        slot="body"
        slot-scope="{ tippyConfig }"
      >
        <div
          v-tippy="tippyConfig"
          class="count-chip u-font-size-6"
        >
          {{ body.title === 0 ? 'NA' : body.title }}
        </div>
      </div>
    </custom-tool-tip>
    <div
      v-if="!data.length"
      class="u-color-grey-base"
    >
      NA
    </div>
  </div>
</template>

<script>
import customToolTip from '@/components/widgets/customToolTip';
import { formatter } from '../../../utils/helpers/formatter';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'recommendationCell',
  components: { customToolTip },
  props: {
    tooltipTitle: {
      type: String,
      default: 'Recommendations'
    },
    labelKey: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    formatterMap: {
      type: Object,
      default() {
        return {
          bid: 'currency'
        };
      }
    },
    isLabelChip: {
      type: Boolean,
      default: false
    },
    recTypeKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    body() {
      return {
        title: this.data.length
      };
    }
  },
  methods: {
    // to format numbers appearing in rec text
    formattedData(recType) {
      const val = recType[this.labelKey];
      let numberFormatter = '';
      for (const recKey in Object.keys(recType)) {
        if (numberFormatter) break;
        for (const formatKey of Object.keys(this.formatterMap)) {
          if (recKey?.toLowerCase()?.includes?.(formatKey?.toLowerCase())) {
            numberFormatter = this.formatterMap[formatKey];
            break;
          }
        }
      }
      if (!numberFormatter) {
        numberFormatter = 'currency';
      }
      const splitString = val?.split(' ');
      if (splitString.length > 1) {
        return splitString
          .map((str) => {
            const res = !Number.isNaN(Number(str))
              ? formatter(str, numberFormatter)
              : str;
            return res;
          })
          .join(' ');
      } else {
        return val;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.tooltip_container {
  max-width: 418px;
  padding: 1.6rem 1.3rem;
  display: flex;
  flex-direction: column;
}
.tag-token {
  padding: 0.4rem 0.8rem;
  border-radius: 2.6rem;
  background-color: #ffa800;
  border: solid 1px #ffffff;
  color: #ffffff;
}
.tag-token:hover {
  background-color: #dd9305;
}
.tag-token.rec-chip {
  padding: 0.4rem 0.8rem;
  border-radius: 2.6rem;
  background-color: rgba(139, 143, 147, 0.1);
  color: #2b333b;
}
.count-chip {
  display: flex;
  justify-content: center;
  width: 2.5rem;
  padding-block: 0.3rem;
  margin-top: -0.5rem;
  border-radius: 2.6rem;
  background-color: #ffa800;
  border: solid 1px #ffffff;
  color: #ffffff;
}
.count-chip:hover {
  background-color: #dd9305;
}
.chips {
  display: flex;
  gap: 1.6rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  box-sizing: border-box;
}
</style>
